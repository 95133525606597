import { default as _91slug_936hmlui8kb0Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/[slug].vue?macro=true";
import { default as founder_45profilesBIYAzgGVkjMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/founder-profiles.vue?macro=true";
import { default as indexE3N1VtjtPsMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/index.vue?macro=true";
import { default as indexCD3pK5DLyhMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/index.vue?macro=true";
import { default as _91slug_93afgLCwOEFsMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/[slug].vue?macro=true";
import { default as engineeringqcKijJGSEMMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/engineering.vue?macro=true";
import { default as indexCgMRRcEOxRMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/index.vue?macro=true";
import { default as _91slug_93bMeaBGRFOHMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/[slug].vue?macro=true";
import { default as financeOuQJ7zPpfhMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/finance.vue?macro=true";
import { default as indexa4oHzP87SMMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/index.vue?macro=true";
import { default as _91slug_93FeuTvPtd22Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/[slug].vue?macro=true";
import { default as impacteatXd5BZL9Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/impact.vue?macro=true";
import { default as indexEHBriwUVPnMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/index.vue?macro=true";
import { default as indexn9hcs5yBjMMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/index.vue?macro=true";
import { default as insightsAFHR2BUgt3Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/insights.vue?macro=true";
import { default as _91slug_93Czl1C5VYeZMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/[slug].vue?macro=true";
import { default as indexns2WIWyXAYMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/index.vue?macro=true";
import { default as investmentWh7pvHNg2CMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/investment.vue?macro=true";
import { default as _91slug_93gyJP7J3bFrMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/[slug].vue?macro=true";
import { default as indexrOXly6g2PcMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/index.vue?macro=true";
import { default as law_45and_45regulationsUxecLnxhGCMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/law-and-regulations.vue?macro=true";
import { default as _91slug_93iIH0zOek8iMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/[slug].vue?macro=true";
import { default as index7gDdKSA5NzMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/index.vue?macro=true";
import { default as sdgss1XK1UdXYmMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/sdgs.vue?macro=true";
import { default as _91slug_930l2HNpZN8TMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/[slug].vue?macro=true";
import { default as index6xoBpLORNxMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/index.vue?macro=true";
import { default as technologyBrNu6PGCJ4Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/technology.vue?macro=true";
import { default as _91slug_93s6WLlo2n9rMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/[slug].vue?macro=true";
import { default as indexveVtknTyxcMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/index.vue?macro=true";
import { default as news6OiHtyGI1XMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/news.vue?macro=true";
import { default as index6lVtqsyRARMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/newsletter/index.vue?macro=true";
import { default as _91slug_93qvS3K3hjB4Meta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/[slug].vue?macro=true";
import { default as indexlkJ70r4zJIMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/index.vue?macro=true";
import { default as recommended_45reads8PrniIQijdMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/recommended-reads.vue?macro=true";
import { default as indexexxeCxW0zTMeta } from "/home/runner/work/kinfolk-blog/kinfolk-blog/pages/unsubscribe/index.vue?macro=true";
export default [
  {
    name: "founder-profiles-slug",
    path: "/founder-profiles/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/[slug].vue")
  },
  {
    name: "founder-profiles-founder-profiles",
    path: "/founder-profiles/founder-profiles",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/founder-profiles.vue")
  },
  {
    name: "founder-profiles",
    path: "/founder-profiles",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/founder-profiles/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/index.vue")
  },
  {
    name: "insights-engineering-slug",
    path: "/insights/engineering/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/[slug].vue")
  },
  {
    name: "insights-engineering-engineering",
    path: "/insights/engineering/engineering",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/engineering.vue")
  },
  {
    name: "insights-engineering",
    path: "/insights/engineering",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/engineering/index.vue")
  },
  {
    name: "insights-finance-slug",
    path: "/insights/finance/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/[slug].vue")
  },
  {
    name: "insights-finance-finance",
    path: "/insights/finance/finance",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/finance.vue")
  },
  {
    name: "insights-finance",
    path: "/insights/finance",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/finance/index.vue")
  },
  {
    name: "insights-impact-slug",
    path: "/insights/impact/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/[slug].vue")
  },
  {
    name: "insights-impact-impact",
    path: "/insights/impact/impact",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/impact.vue")
  },
  {
    name: "insights-impact",
    path: "/insights/impact",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/impact/index.vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/index.vue")
  },
  {
    name: "insights-insights",
    path: "/insights/insights",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/insights.vue")
  },
  {
    name: "insights-investment-slug",
    path: "/insights/investment/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/[slug].vue")
  },
  {
    name: "insights-investment",
    path: "/insights/investment",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/index.vue")
  },
  {
    name: "insights-investment-investment",
    path: "/insights/investment/investment",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/investment/investment.vue")
  },
  {
    name: "insights-law-and-regulations-slug",
    path: "/insights/law-and-regulations/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/[slug].vue")
  },
  {
    name: "insights-law-and-regulations",
    path: "/insights/law-and-regulations",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/index.vue")
  },
  {
    name: "insights-law-and-regulations-law-and-regulations",
    path: "/insights/law-and-regulations/law-and-regulations",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/law-and-regulations/law-and-regulations.vue")
  },
  {
    name: "insights-sdgs-slug",
    path: "/insights/sdgs/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/[slug].vue")
  },
  {
    name: "insights-sdgs",
    path: "/insights/sdgs",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/index.vue")
  },
  {
    name: "insights-sdgs-sdgs",
    path: "/insights/sdgs/sdgs",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/sdgs/sdgs.vue")
  },
  {
    name: "insights-technology-slug",
    path: "/insights/technology/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/[slug].vue")
  },
  {
    name: "insights-technology",
    path: "/insights/technology",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/index.vue")
  },
  {
    name: "insights-technology-technology",
    path: "/insights/technology/technology",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/insights/technology/technology.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/index.vue")
  },
  {
    name: "news-news",
    path: "/news/news",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/news/news.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/newsletter/index.vue")
  },
  {
    name: "recommended-reads-slug",
    path: "/recommended-reads/:slug()",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/[slug].vue")
  },
  {
    name: "recommended-reads",
    path: "/recommended-reads",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/index.vue")
  },
  {
    name: "recommended-reads-recommended-reads",
    path: "/recommended-reads/recommended-reads",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/recommended-reads/recommended-reads.vue")
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    component: () => import("/home/runner/work/kinfolk-blog/kinfolk-blog/pages/unsubscribe/index.vue")
  }
]